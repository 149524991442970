import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import Collection from '../../../components/collection'


const metaTags = {
  description: 'Find helpful links and downloadable content for caregivers of patients treated with SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Caregiver Downloads and Support'
}

const resources = [
  {
    thumb: 'newcargiverkit_thumbnail.png',
    title: 'Caregiver Kit',
    copy: 'Get an all-in-one SUPPRELIN<sup>&reg;</sup> LA caregiver&#39;s kit sent directly to your home',
    href: '/patients/caregiver-signup',
    pdf: false,
    external: false,
    alt: 'SUPPRELIN LA Caregiver Information'
  },
  {
    thumb: 'SP-05378_caregiverbro_thumbnail.png',
    title: 'Caregiver Brochure',
    copy: 'Important information on CPP and how SUPPRELIN<sup>&reg;</sup> LA can help',
    href: '/pdfs/SP-05689_Supprelin_LA_Caregiver_Brochure_Digital.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Caregiver Brochure'
  },
  {
    thumb: 'SP-05379_gradybear_thumbnail.png',
    title: 'Grady Bear',
    copy: 'Storybook that makes CPP easy for kids to understand',
    href: '/pdfs/SP-05715_SLA_Grady_Bear_Storybook_-_DIGITAL_final.pdf',
    pdf: true,
    external: false,
    alt: 'Grady Bear Book'
  },
  {
    thumb: 'SP-05697_appreminder_thumbnail.png',
    title: 'Appointment Reminder Card',
    copy: `Helps you keep your child's scheduled appointments`,
    href: '/pdfs/SP-05697_Appointment_Reminder_Card_R1_Digital.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Appointment Reminder Card'
  },
  {
    thumb: 'SP-05379_5reasons_thumbnail.png',
    title: '5 Good Reasons Tool',
    copy: `Helps you discuss SUPPRELIN<sup>&reg;</sup> LA with your child's doctor`,
    href: '/pdfs/SP-05739_5_Good_Reasons_Tearsheet_PRINT_DIGITAL_Digital.pdf',
    pdf: true,
    external: false,
    alt: '5 Reasons to Ask About SUPPRELIN_LA'
  },
  {
    thumb: 'SP-05379_shares_thumbnail.png',
    title: 'SHARES Program',
    copy: 'Information on copay assistance',
    href: '/pdfs/SP-05685_SHARES_Leave_Behind_R1_Digital.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Shares Program'
  },
  {
    thumb: 'SP-05374_Caregiver_Spanish.png',
    title: 'Spanish: Caregiver Brochure',
    copy: 'Important information on CPP and how SUPPRELIN<sup>&reg;</sup> LA can help',
    href: '/pdfs/SP-05690_Supprelin_LA_Caregiver_Brochure-Spanish-digital.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Spanish Caregiver Brochure'
  },
  {
    thumb: 'SP-05383_5reasons_Spanish.png',
    title: 'Spanish: 5 Good Reasons Tool',
    copy: `Helps you discuss SUPPRELIN<sup>&reg;</sup> LA with your child's doctor`,
    href: '/pdfs/SP-05383_5_Good_Reasons_Download_Spanish.pdf',
    pdf: true,
    external: false,
    alt: '5 Reasons to Ask About SUPPRELIN LA Spanish'
  },
  {
    thumb: 'SP-05576_appointment_card_Spanish.png',
    title: 'Spanish: Appointment Reminder Card',
    copy: `Helps you keep your child's scheduled appointments`,
    href: '/pdfs/SP-05697_Appointment_Reminder_Card_R1_Digital-Spanish.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Appointment Reminder Card'
  },
  {
    thumb: 'SP-05379_hgfound_thumbnail.png',
    title: 'HGFound.org',
    copy: 'The Human Growth Foundation leads the way in providing research, education, support, and advocacy in areas of growth or growth-hormone disorders',
    href: 'https://www.hgfound.org/',
    pdf: false,
    external: true,
    alt: 'hgfound.org'
  },
  {
    thumb: 'SP-05379_magicfound_thumbnail.png',
    title: 'MagicFoundation.org',
    copy: 'The MAGIC Foundation<sup>&reg;</sup> is the world’s largest organization for children and adults with growth-related disorders',
    href: 'https://www.magicfoundation.org/',
    pdf: false,
    external: true,
    alt: 'magicfoundation.org'
  }
]

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Caregiver information</h1>
        <h2>Here are some helpful links and downloadable content about central precocious puberty (CPP)</h2>
      </Col>
    </Row>
    <Collection collection={resources} pageName='patient downloads support' />
  </Layout>
)

export default IndexPage
